<i18n src="@/i18n/userlog.json"></i18n>

<template lang="pug">
    .userlog
        section.light
            .section-content-wrapper
                h1 Запросы к API
                .userlog-filter
                    el-form(v-model="userlog_filter" ref="userlog-filter" label-position="top")
                        el-form-item
                            .form-row
                                el-select(v-model="userlog_filter.file")
                                    el-option(v-for="file in userlog_files" :key="file" :label="file" :value="file")
                                el-input(v-model="userlog_filter.q" placeholder="Поиск" suffix-icon="el-icon-search" clearable)
                        el-form-item
                            .form-row
                                el-button(v-for="(name,alias) in userlog_aliases" :key="alias" @click="toggleFilterAlias(alias)" :type="filterAliasChecked(alias) ? 'primary' : 'warning'" :plain="!filterAliasChecked(alias)" size="small") {{ name }}
                .userlog-records(v-if="userlog.data.length")
                    .userlog-record(v-for="record in userlog.data")
                        div
                            b {{ record.subscriber ? record.subscriber.phone : '' }} {{ $t(`userlog.alias.${record.alias}`) || '' }}
                            div(v-if="record.created_at") {{ $moment.utc(record.created_at).local().format('llll') }}
                        component(:is="userLogRecordComponentName(record)" :record="record")
                .pagination(v-if="userlog.has_more")
                    el-button(@click="getMoreUserlog" type="primary") Показать ещё

</template>

<script>
import {mapActions} from 'vuex';
import {GET_USERLOG_FILES, GET_USERLOG} from '@/store/types';

const default_userlog_filter = () => {
    return {
        file: '...',
        aliases: [],
        q: '',
    }
}

const default_userlog = () => {
    return {
        current_page: 1,
        data: [],
    }
}

export default {
    components: {
        'DefaultUserLogRecord': () => import('@/components/Manage/Userlog/DefaultUserLogRecord'),
        'SubscriptionSaveBegin': () => import('@/components/Manage/Userlog/SubscriptionSaveBegin'),
    },
    data() {
        return {
            userlog_aliases: {
                'login': 'Авторизация',
                'sender.suggestions': 'Популярные отправители',
                'subscriber.subscriptions.get': 'Мои отписки',
                'subscriber.subscription.save': 'Отписка',
                'subscriber.terms.request.begin': 'Запрос кода соглашения',
                'subscriber.terms.verification.begin': 'Верификация кода соглашения',
            },
            userlog_files: [],
            userlog: default_userlog(),
            userlog_filter: default_userlog_filter(),
        }
    },
    created() {
        this.GET_USERLOG_FILES()
            .then((files) => {
                this.userlog_files = files;
                this.userlog_filter.file = this.userlog_files[this.userlog_files.length - 1];
            })
            .then(() => {
                // Watch filter form changes
                this.$watch('userlog_filter', () => {
                    this.getUserlog(true)
                }, {
                    // Run automatically?
                    immediate: true,
                    // Watch all properties changes
                    deep: true
                });
            });
    },
    methods: {
        ...mapActions([
            GET_USERLOG_FILES,
            GET_USERLOG,
        ]),
        userLogRecordComponentName(record){
            switch (record.alias){
                case 'subscriber.subscription.save.begin':
                case 'subscriber.subscription.save.ok':
                    return 'SubscriptionSaveBegin';
                default:
                    return 'DefaultUserLogRecord';
            }

        },
        resetUserlog() {
            this.userlog = default_userlog()
        },
        getMoreUserlog() {
            this.userlog.current_page++

            return this.getUserlog()
        },
        getUserlog(with_reset = false) {
            if (with_reset) this.resetUserlog()
            return this.GET_USERLOG({...this.userlog_filter, ...{page: this.userlog.current_page}}).then((userlog) => {
                let buffer = (this.userlog.data) ? this.userlog.data : []

                this.userlog = userlog

                Object.assign(this.userlog.data, buffer.concat(this.userlog.data))
            }).catch((error) => {
                if (error.response.data.errors) {
                    this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                        return this.$t(message)
                    }))
                }

                return Promise.reject(error)
            });
        },
        toggleFilterAlias(alias) {
            const alias_index = this.userlog_filter.aliases.indexOf(alias);
            if (alias_index > -1) {
                this.userlog_filter.aliases.splice(alias_index, 1);
            } else {
                this.userlog_filter.aliases.push(alias);
            }
        },
        filterAliasChecked(alias) {
            return this.userlog_filter.aliases.indexOf(alias) > -1
        },
        humanizeUserlogFileCreatedAt() {
        },
    },
}
</script>

<style lang="scss" scoped>
.userlog {
    .light {
        background: $color--background;
        color: $color--black;
    }

    .userlog-record {
        @include border(1px dotted $color--secondary-dark, bottom);

        padding-bottom: 1rem;
        margin-bottom: 1rem;

        &-data {
            @include font--small;

            padding-top: 1rem;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

}
</style>
